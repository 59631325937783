import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Typography } from "@material-tailwind/react";
import {
  UsersIcon,
  DocumentIcon,
  PlusCircleIcon,
  CurrencyDollarIcon,
  ClipboardDocumentListIcon,
  ChartBarIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";

const cardData = [
  {
    title: "العملاء",
    path: "/customers",
    icon: <UsersIcon className="h-10 w-10 text-blue-500" />,
  },
  {
    title: "إضافة عميل",
    path: "/form",
    icon: <UserPlusIcon className="h-10 w-10 text-green-500" />,
  },
  {
    title: "المجموعات",
    path: "/add_category",
    icon: <PlusCircleIcon className="h-10 w-10 text-purple-500" />,
  },
  {
    title: "تقارير المواد",
    path: "/item_reports",
    icon: <ClipboardDocumentListIcon className="h-10 w-10 text-red-500" />,
  },
  {
    title: "إضافة فاتورة",
    path: "/add_invoice",
    icon: <CurrencyDollarIcon className="h-10 w-10 text-yellow-500" />,
  },
  {
    title: "تقارير الفواتير",
    path: "/invoice",
    icon: <ChartBarIcon className="h-10 w-10 text-indigo-500" />,
  },
  {
    title: "إضافة مادة",
    path: "/add_item",
    icon: <PlusCircleIcon className="h-10 w-10 text-pink-500" />,
  },
];

function CardLink({ title, path, icon }) {
  const navigate = useNavigate();

  return (
    <Card
      className="shadow-lg rounded-2xl transition-transform transform hover:scale-105 cursor-pointer bg-gradient-to-r from-blue-500 to-blue-700"
      onClick={() => navigate(path)}
    >
      <CardBody className="flex flex-row md:flex-col items-center md:justify-center justify-between md:py-8 py-2 px-8">
        <div className="p-2 bg-white rounded-full mb-4 md:mb-0 md:mr-0 mr-4">
          {icon}
        </div>
        <Typography className="font-bold text-white text-lg font-tajawal">
          {title}
        </Typography>
      </CardBody>
    </Card>
  );
}

function Home() {
  return (
    <section className="container mx-auto px-4 md:px-6">
      <div className="mt-6 mb-4 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 items-center md:gap-6 gap-4">
        {cardData.map((props, key) => (
          <CardLink key={key} {...props} />
        ))}
      </div>
    </section>
  );
}

export default Home;
