import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { loginUser } from "../../../context/actions/postaction";
import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Button,
  Alert,
} from "@material-tailwind/react";
import logo2 from "../../images/logo2.png";
import "../../../App.css";

export function Login({ onLogin }) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState();

  useEffect(() => {
    if (success !== null) {
      const timer = setTimeout(() => {
        setSuccess(null);
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [success]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const itemData = { email, password };

    try {
      const response = await dispatch(loginUser(itemData));

      if (response.status === 200) {
        setSuccess(true);
        onLogin(true);
        setEmail("");
        setPassword("");
        Navigate(`/`); // إعادة التوجيه إلى الصفحة الرئيسية عند تسجيل الدخول بنجاح
      }
    } catch (error) {
      setSuccess(false);
      setError(`${error}`); // يمكنك أيضًا عرض رسالة خطأ للمستخدم
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex items-center justify-center h-screen"
    >
      <Card className="w-80 black-foto">
        <CardHeader
          color="blue"
          variant="gradient"
          className="mb-4 grid h-28 place-items-center"
        >
          <Typography
            className="text-center flex justify-center"
            variant="h4"
            color="white"
          >
            <img style={{ width: 200, height: "100%" }} src={logo2} alt="" />
          </Typography>
        </CardHeader>

        <CardBody className="flex flex-col gap-4">
          {success === true ? (
            <div dir="rtl" className="flex w-full flex-col gap-2">
              <Alert className="font-tajawal text-center" color="green">
                تم الحذف بنجاح.
              </Alert>
            </div>
          ) : success === false && error ? (
            <div dir="rtl" className="flex w-full flex-col  ">
              <Alert className="font-tajawal py-2 text-[11px] " color="red">
                {error}
              </Alert>
            </div>
          ) : null}
          <Input
            className="font-tajawal"
            label="Email"
            size="lg"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            className="font-tajawal"
            label="Password"
            size="lg"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </CardBody>
        <CardFooter className="pt-0">
          <Button color="blue" className="font-tajawal" fullWidth type="submit">
            تسجيل الدخول
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
}
