// src/api.js

import axios from "axios";

const BASE_URL = "https://parseapi.back4app.com/classes/category";
const APP_ID = "jBcsdUCAdIxPdSwIpH7bb0wvUXuSCkZOWd1bY7GX";
const API_KEY = "rwJiEgAXH9h3FlWmdzzNU7HJooTyZ3deG9r1VkFm";

const category = axios.create({
  baseURL: BASE_URL,
  headers: {
    "X-Parse-Application-Id": APP_ID,
    "X-Parse-REST-API-Key": API_KEY,
  },
});

export default category;
