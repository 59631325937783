// InvoiceReports.jsx
import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  Typography,
  Alert,
  Input,
  Button,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoices,
  getClients,
  getInvoiceDetails,
} from "../../../context/actions/getaction";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import InvoiceToPrint from "../form/InvoiceToPrint";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";

const InvoiceReports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { invoices, loading, error } = useSelector((state) => state.invoices);
  const { clients } = useSelector((state) => state.clients);
  const {
    details: invoiceDetails,
    loading: detailsLoading,
    error: detailsError,
  } = useSelector((state) => state.invoiceDetails);

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const invoicesPerPage = 10;
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const componentRef = useRef();

  useEffect(() => {
    dispatch(getInvoices());
    dispatch(getClients());
  }, [dispatch]);

  const getClientNameById = (clientId) => {
    const client = clients.find((client) => client.objectId === clientId);
    return client ? client.name : "غير محدد";
  };

  const getClientPhoneById = (clientId) => {
    const client = clients.find((client) => client.objectId === clientId);
    return client ? client.phone : "غير محدد";
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString();
  };

  const handleInvoiceClick = (invoice) => {
    navigate(`/invoice_details/${invoice.objectId}`);
  };

  const formatProducts = (details) => {
    return details.map((detail) => ({
      productId: detail.item.objectId,
      productName: detail.item.name,
      price: Number(detail.price),
      quantity: Number(detail.quantity),
      total: Number(detail.total),
    }));
  };

  const filteredInvoices = invoices.filter((invoice) => {
    const clientName = getClientNameById(invoice.user.objectId).toLowerCase();
    const invoiceNumber = invoice.objectId.toLowerCase();
    return (
      clientName.includes(searchQuery.toLowerCase()) ||
      invoiceNumber.includes(searchQuery.toLowerCase())
    );
  });

  // Pagination logic
  const indexOfLastInvoice = currentPage * invoicesPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
  const currentInvoices = filteredInvoices.slice(
    indexOfFirstInvoice,
    indexOfLastInvoice
  );

  const totalPages = Math.ceil(filteredInvoices.length / invoicesPerPage);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="p-4 lg:p-12">
      <Card dir="rtl" className="p-6 space-y-4">
        <Typography
          variant="h4"
          color="blue-gray"
          className="font-tajawal text-center mb-4"
        >
          تقارير الفواتير
        </Typography>

        {/* Search Input */}
        <div className="flex justify-center mb-4">
          <Input
            color="blue-gray"
            size="lg"
            placeholder="أبحث عن اسم العميل"
            className="font-tajawal w-full max-w-md"
            labelProps={{ className: "before:content-none after:content-none" }}
            icon={<MagnifyingGlassIcon className="h-5 w-5" />}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {loading && (
          <Alert className="font-tajawal text-center" color="blue">
            جاري التحميل...
          </Alert>
        )}

        {error && (
          <Alert className="font-tajawal text-center" color="red">
            حدث خطأ: {error}
          </Alert>
        )}

        {!loading && !error && (
          <>
            <CardBody className="overflow-scroll px-0">
              <table className="w-full min-w-max table-auto mx-2">
                <thead>
                  <tr>
                    {/* Table headers */}
                    <th className="px-4 py-2 text-center bg-blue-gray-500 rounded-lg">
                      <Typography
                        variant="small"
                        color="white"
                        className="font-bold font-tajawal"
                      >
                        رقم الفاتورة
                      </Typography>
                    </th>
                    <th className="px-4 py-2 text-center bg-blue-gray-500 rounded-lg">
                      <Typography
                        variant="small"
                        color="white"
                        className="font-bold font-tajawal"
                      >
                        اسم العميل
                      </Typography>
                    </th>
                    <th className="px-4 py-2 text-center bg-blue-gray-500 rounded-lg">
                      <Typography
                        variant="small"
                        color="white"
                        className="font-bold font-tajawal"
                      >
                        التاريخ
                      </Typography>
                    </th>
                    <th className="px-4 py-2 text-center bg-blue-gray-500 rounded-lg">
                      <Typography
                        variant="small"
                        color="white"
                        className="font-bold font-tajawal"
                      >
                        المبلغ الإجمالي
                      </Typography>
                    </th>
                    <th className="px-4 py-2 text-center bg-blue-gray-500 rounded-lg">
                      <Typography
                        variant="small"
                        color="white"
                        className="font-bold font-tajawal"
                      >
                        المبلغ المدفوع
                      </Typography>
                    </th>
                    <th className="px-4 py-2 text-center bg-blue-gray-500 rounded-lg">
                      <Typography
                        variant="small"
                        color="white"
                        className="font-bold font-tajawal"
                      >
                        المبلغ المتبقي
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentInvoices.map((invoice) => (
                    <tr
                      key={invoice.objectId}
                      className="border-b border-blue-gray-200 hover:bg-blue-gray-100 transition cursor-pointer"
                      onClick={() => handleInvoiceClick(invoice)}
                    >
                      <td className="p-4 text-center">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal font-tajawal"
                        >
                          {invoice.objectId}
                        </Typography>
                      </td>
                      <td className="p-4 text-center">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal font-tajawal"
                        >
                          {getClientNameById(invoice.user.objectId)}
                        </Typography>
                      </td>
                      <td className="p-4 text-center">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal font-tajawal"
                        >
                          {formatDate(invoice.date.iso)}
                        </Typography>
                      </td>
                      <td className="p-4 text-center">
                        <Typography
                          variant="small"
                          color="green"
                          className="font-normal font-tajawal"
                        >
                          {Number(invoice.totalAmount).toLocaleString("en-US")}
                        </Typography>
                      </td>
                      <td className="p-4 text-center">
                        <Typography
                          variant="small"
                          color="blue"
                          className="font-normal font-tajawal"
                        >
                          {Number(invoice.amountPaid).toLocaleString("en-US")}
                        </Typography>
                      </td>
                      <td className="p-4 text-center">
                        <Typography
                          variant="small"
                          color="red"
                          className="font-normal font-tajawal"
                        >
                          {Number(invoice.remainingAmount).toLocaleString(
                            "en-US"
                          )}
                        </Typography>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>

            {/* Pagination Controls */}
            <div className="flex items-center justify-between p-4">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal font-tajawal"
              >
                صفحة {currentPage} من {totalPages}
              </Typography>
              <div className="flex gap-2">
                <Button
                  className="font-tajawal"
                  color="blue-gray"
                  variant="outlined"
                  size="sm"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  السابق
                </Button>
                <Button
                  className="font-tajawal"
                  color="blue-gray"
                  variant="outlined"
                  size="sm"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  التالي
                </Button>
              </div>
            </div>

            {/* Invoice Details */}
            {selectedInvoice && (
              <div className="mt-6">
                <Typography
                  variant="h5"
                  color="blue-gray"
                  className="font-tajawal text-center mb-4"
                >
                  تفاصيل الفاتورة
                </Typography>

                {detailsLoading ? (
                  <Alert className="font-tajawal text-center" color="blue">
                    جاري تحميل تفاصيل الفاتورة...
                  </Alert>
                ) : detailsError ? (
                  <Alert className="font-tajawal text-center" color="red">
                    حدث خطأ: {detailsError}
                  </Alert>
                ) : (
                  <>
                    {/* Display the invoice details using InvoiceToPrint */}
                    <InvoiceToPrint
                      ref={componentRef}
                      products={formatProducts(invoiceDetails)}
                      clientId={selectedInvoice.user.objectId}
                      clientName={getClientNameById(
                        selectedInvoice.user.objectId
                      )}
                      phoneNumber={getClientPhoneById(
                        selectedInvoice.user.objectId
                      )}
                      date={formatDate(selectedInvoice.date.iso)}
                      totalAmount={Number(selectedInvoice.totalAmount)}
                      amountPaid={Number(selectedInvoice.amountPaid)}
                      remainingAmount={Number(selectedInvoice.remainingAmount)}
                    />

                    {/* Print Button */}
                    <div className="flex justify-center mt-4">
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            color="green"
                            className="font-tajawal w-full md:w-auto"
                          >
                            طباعة الفاتورة
                          </Button>
                        )}
                        content={() => componentRef.current}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default InvoiceReports;
