import React from "react";
import { Card, Typography } from "@material-tailwind/react";

const InvoiceToPrint = React.forwardRef((props, ref) => {
  const {
    invoiceNumber, // أضفنا رقم الفاتورة هنا
    products,
    clientId,
    clientName,
    phoneNumber,
    date,
    totalAmount,
    amountPaid,
    remainingAmount,
  } = props;

  return (
    <div ref={ref} className="p-6 font-tajawal" dir="rtl">
      <Card className="p-6 font-tajawal" dir="rtl">
        <Typography variant="h4" className="font-tajawal text-center">
          فاتورة مبيعات
        </Typography>

        {/* إضافة رقم الفاتورة */}
        <div className="text-right mb-4">
          <Typography className="font-tajawal">
            رقم الفاتورة: {invoiceNumber || "غير محدد"}
          </Typography>
        </div>

        <div className="flex justify-between mb-4">
          {/* معلومات العميل والتاريخ */}
          <div className="text-right">
            <Typography className=" font-tajawal">
              اسم العميل: {clientName || "غير محدد"}
            </Typography>
            <Typography className="font-tajawal">
              رقم الهاتف: {phoneNumber || "غير محدد"}
            </Typography>
            <Typography className="font-tajawal">التاريخ: {date}</Typography>
          </div>
          {/* العنوان ومعلومات الشركة */}
          <div className="text-right">
            <Typography className="font-tajawal">شركة الرافدين</Typography>
            <Typography className="font-tajawal">تكريت، شارع جميل</Typography>
            <Typography className="font-tajawal">هاتف: 07729209397</Typography>
          </div>
        </div>

        {/* جدول المنتجات */}
        <table className="w-full border-collapse border border-gray-400 mt-4">
          <thead>
            <tr>
              <th className="border border-gray-300 p-2">الصنف</th>
              <th className="border border-gray-300 p-2">الكمية</th>
              <th className="border border-gray-300 p-2">السعر</th>
              <th className="border border-gray-300 p-2">المجموع</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={index}>
                <td className="border border-gray-300 p-2">
                  {product.productName}
                </td>
                <td className="border border-gray-300 p-2">
                  {product.quantity}
                </td>
                <td className="border border-gray-300 p-2">
                  {product.price.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}
                </td>
                <td className="border border-gray-300 p-2">
                  {product.total.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* المجموع */}
        <div className="mt-4">
          <Typography className="font-tajawal">
            المجموع الكلي:{" "}
            {totalAmount.toLocaleString("en-US", { minimumFractionDigits: 2 })}
          </Typography>
          <Typography className="font-tajawal">
            المبلغ المسدد:{" "}
            {amountPaid.toLocaleString("en-US", { minimumFractionDigits: 2 })}
          </Typography>
          <Typography className="font-tajawal">
            المتبقي:{" "}
            {remainingAmount.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}
          </Typography>
        </div>
      </Card>
    </div>
  );
});

export default InvoiceToPrint;
