import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { reducerAddItem, reducerAddItems } from "../reducer/reducerPostData";
import {
  invoiceDetailsReducer,
  invoicesReducer,
  reducerGetCategory,
  reducerGetClients,
  reducerGetItems,
  reducerGetProduct,
} from "../reducer/reducerGetData";

const rootReducer = combineReducers({
  item: reducerAddItem,
  clients: reducerGetClients,
  product: reducerGetProduct,
  items: reducerGetItems,
  category: reducerGetCategory,
  itemsAdd: reducerAddItems,
  invoices: invoicesReducer,
  invoiceDetails: invoiceDetailsReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
