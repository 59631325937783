// Projects
const Projects = {
  loading: false,
  projects: [],
  error: null,
};

export const reducerGetProjects = (state = Projects, action) => {
  switch (action.type) {
    case "GET_PROJECTS_START":
      return { ...state, loading: true, error: null };
    case "GET_PROJECTS_SUCCESS":
      return { ...state, loading: false, projects: action.payload };
    case "GET_PROJECTS_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// Categories
const Categories = {
  loading: false,
  categories: [],
  error: null,
};

export const reducerGetCategories = (state = Categories, action) => {
  switch (action.type) {
    case "GET_CATEGORIES_START":
      return { ...state, loading: true, error: null };
    case "GET_CATEGORIES_SUCCESS":
      return { ...state, loading: false, categories: action.payload };
    case "GET_CATEGORIES_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// News
const Clients = {
  loading: false,
  clients: [],
  error: null,
};

export const reducerGetClients = (state = Clients, action) => {
  switch (action.type) {
    case "GET_CLIENTS_START":
      return { ...state, loading: true, error: null };
    case "GET_CLIENTS_SUCCESS":
      return { ...state, loading: false, clients: action.payload };
    case "GET_CLIENTS_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// Items
const Items = {
  loading: false,
  items: [],
  error: null,
};

export const reducerGetItems = (state = Items, action) => {
  switch (action.type) {
    case "GET_ITEMS_START":
      return { ...state, loading: true, error: null };
    case "GET_ITEMS_SUCCESS":
      return { ...state, loading: false, items: action.payload };
    case "GET_ITEMS_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// Category
const Category = {
  loading: false,
  category: [],
  error: null,
};

export const reducerGetCategory = (state = Category, action) => {
  switch (action.type) {
    case "GET_CATEGORY_START":
      return { ...state, loading: true, error: null };
    case "GET_CATEGORY_SUCCESS":
      return { ...state, loading: false, category: action.payload };
    case "GET_CATEGORY_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const Product = {
  loading: false,
  product: [],
  error: null,
};
export const reducerGetProduct = (state = Product, action) => {
  switch (action.type) {
    case "GET_PRODUCT_START":
      return { ...state, loading: true, error: null };
    case "GET_PRODUCT_SUCCESS":
      return { ...state, loading: false, product: action.payload };
    case "GET_PRODUCT_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const DeleteProduct = {
  loading: false,
  delete: [],
  error: null,
};
export const reducerDeleteProduct = (state = DeleteProduct, action) => {
  switch (action.type) {
    case "DELETE_PRODUCT_START":
      return { ...state, loading: true, error: null };
    case "DELETE_PRODUCT_SUCCESS":
      return { ...state, loading: false, delete: action.payload };
    case "DELETE_PRODUCT_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const DeleteItems = {
  loading: false,
  deleteItems: [],
  error: null,
};
export const reducerDeleteItems = (state = DeleteItems, action) => {
  switch (action.type) {
    case "DELETE_ITEMS_START":
      return { ...state, loading: true, error: null };
    case "DELETE_ITEMS_SUCCESS":
      return { ...state, loading: false, deleteItems: action.payload };
    case "DELETE_ITEMS_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const DeleteUser = {
  loading: false,
  user: [],
  error: null,
};
export const reducerDeleteUser = (state = DeleteUser, action) => {
  switch (action.type) {
    case "DELETE_USER_START":
      return { ...state, loading: true, error: null };
    case "DELETE_USER_SUCCESS":
      return { ...state, loading: false, user: action.payload };
    case "DELETE_USER_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// context/reducers/invoicesReducer.jsx
const initialState = {
  invoices: [],
  loading: false,
  error: null,
};

export const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_INVOICES_START":
      return { ...state, loading: true, error: null };
    case "GET_INVOICES_SUCCESS":
      return { ...state, loading: false, invoices: action.payload };
    case "GET_INVOICES_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialStateDetails = {
  loading: false,
  invoiceDetails: [],
  error: null,
};

export const invoiceDetailsReducer = (state = initialStateDetails, action) => {
  switch (action.type) {
    case "GET_INVOICE_DETAILS_START":
      return { ...state, loading: true, error: null };
    case "GET_INVOICE_DETAILS_SUCCESS":
      return { ...state, loading: false, invoiceDetails: action.payload };
    case "GET_INVOICE_DETAILS_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
