import axios from "axios";
export const loginUser = (item) => {
  return async (dispatch) => {
    dispatch({ type: "LOGIN_START" });

    if (!item || typeof item !== "object" || !item.email || !item.password) {
      const error = new Error("Invalid login data");
      error.response = {
        status: 400,
        message: "Bad Request: Invalid login data",
      };
      dispatch({ type: "LOGIN_ERROR", payload: error.message });
      throw error;
    }

    const BASE_URL = "https://parseapi.back4app.com/classes/admin";
    const APP_ID = "jBcsdUCAdIxPdSwIpH7bb0wvUXuSCkZOWd1bY7GX";
    const API_KEY = "rwJiEgAXH9h3FlWmdzzNU7HJooTyZ3deG9r1VkFm";

    let config = {
      method: "get",
      url: `${BASE_URL}?where={"email":"${item.email}","password":"${item.password}"}`,
      headers: {
        "X-Parse-Application-Id": APP_ID,
        "X-Parse-REST-API-Key": API_KEY,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.request(config);
      if (response.status === 200 && response.data.results.length > 0) {
        dispatch({ type: "LOGIN_SUCCESS", payload: response.data.results[0] });
        return response;
      } else {
        throw "خطاء في اسم المستخدم او كلمة المرور";
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      dispatch({ type: "LOGIN_ERROR", payload: errorMessage });
      throw error;
    }
  };
};

export const addUser = (item) => {
  return async (dispatch) => {
    dispatch({ type: "ADD_ITEM_START" });
    const BASE_URL = "https://parseapi.back4app.com/classes/users";
    const APP_ID = "jBcsdUCAdIxPdSwIpH7bb0wvUXuSCkZOWd1bY7GX";
    const API_KEY = "rwJiEgAXH9h3FlWmdzzNU7HJooTyZ3deG9r1VkFm";

    // تأكد من أن الحقول يتم إرسالها بالتنسيق الصحيح
    const newItem = {
      name: item.name,
      phone: Number(item.phone), // التأكد من تحويل رقم الهاتف إلى رقم
      date: item.date, // يجب أن يكون التاريخ نصًا
    };

    let config = {
      method: "post",
      baseURL: BASE_URL,
      headers: {
        "X-Parse-Application-Id": APP_ID,
        "X-Parse-REST-API-Key": API_KEY,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(newItem),
    };

    try {
      const response = await axios.request(config);
      dispatch({ type: "ADD_USER_SUCCESS", payload: response.data });
      return response;
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      dispatch({ type: "ADD_USER_ERROR", payload: error.message });
      throw error;
    }
  };
};

export const addCategory = (item) => {
  return async (dispatch) => {
    dispatch({ type: "ADD_CATEGORY_START" });
    const BASE_URL = "https://parseapi.back4app.com/classes/category";
    const APP_ID = "jBcsdUCAdIxPdSwIpH7bb0wvUXuSCkZOWd1bY7GX";
    const API_KEY = "rwJiEgAXH9h3FlWmdzzNU7HJooTyZ3deG9r1VkFm";

    const newItem = { ...item, phone: Number(item.phone) };

    let config = {
      method: "post",
      baseURL: BASE_URL,
      headers: {
        "X-Parse-Application-Id": APP_ID,
        "X-Parse-REST-API-Key": API_KEY,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(newItem),
    };

    try {
      const response = await axios.request(config);
      dispatch({ type: "ADD_CATEGORY_SUCCESS", payload: response.data });
      return response;
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      dispatch({ type: "ADD_CATEGORY_ERROR", payload: error.message });
      throw error;
    }
  };
};

export const addProduct = (item) => {
  return async (dispatch) => {
    dispatch({ type: "ADD_PRODUCT_START" });
    const BASE_URL = "https://parseapi.back4app.com/classes/product";
    const APP_ID = "jBcsdUCAdIxPdSwIpH7bb0wvUXuSCkZOWd1bY7GX";
    const API_KEY = "rwJiEgAXH9h3FlWmdzzNU7HJooTyZ3deG9r1VkFm";

    // Convert price to a number and type to a string
    const newItem = {
      ...item,
      price: Number(item.price),
      type: String(item.type),
    };

    let config = {
      method: "post",
      baseURL: BASE_URL,
      headers: {
        "X-Parse-Application-Id": APP_ID,
        "X-Parse-REST-API-Key": API_KEY,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(newItem),
    };

    try {
      const response = await axios.request(config);
      dispatch({ type: "ADD_PRODUCT_SUCCESS", payload: response.data });
      return response;
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      dispatch({ type: "ADD_PRODUCT_ERROR", payload: error.message });
      throw error;
    }
  };
};

export const deleteProduct = (item) => {
  return async (dispatch) => {
    dispatch({ type: "DELETE_PRODUCT_START" });
    const BASE_URL = "https://parseapi.back4app.com/classes/product";
    const APP_ID = "jBcsdUCAdIxPdSwIpH7bb0wvUXuSCkZOWd1bY7GX";
    const API_KEY = "rwJiEgAXH9h3FlWmdzzNU7HJooTyZ3deG9r1VkFm";

    let config = {
      method: "post",
      baseURL: BASE_URL,
      headers: {
        "X-Parse-Application-Id": APP_ID,
        "X-Parse-REST-API-Key": API_KEY,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(item),
    };

    try {
      const response = await axios.request(config);
      dispatch({ type: "DELETE_PRODUCT_SUCCESS", payload: response.data });
      return response;
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      dispatch({ type: "DELETE_PRODUCT_ERROR", payload: error.message });
      throw error;
    }
  };
};

export const addItems = (item) => {
  return async (dispatch) => {
    dispatch({ type: "ADD_ITEMS_START" });
    const BASE_URL = "https://parseapi.back4app.com/classes/items";
    const APP_ID = "jBcsdUCAdIxPdSwIpH7bb0wvUXuSCkZOWd1bY7GX";
    const API_KEY = "rwJiEgAXH9h3FlWmdzzNU7HJooTyZ3deG9r1VkFm";

    const newItem = { ...item, price: Number(item.price) };

    let config = {
      method: "post",
      baseURL: BASE_URL,
      headers: {
        "X-Parse-Application-Id": APP_ID,
        "X-Parse-REST-API-Key": API_KEY,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(newItem),
    };

    try {
      const response = await axios.request(config);
      dispatch({ type: "ADD_ITEMS_SUCCESS", payload: response.data });
      return response;
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      dispatch({ type: "ADD_ITEMS_ERROR", payload: error.message });
      throw error;
    }
  };
};

export const addInvoice = (item) => {
  return async (dispatch) => {
    dispatch({ type: "ADD_INVOICE_START" });
    const BASE_URL = "https://parseapi.back4app.com/classes/invoice";
    const APP_ID = "jBcsdUCAdIxPdSwIpH7bb0wvUXuSCkZOWd1bY7GX";
    const API_KEY = "rwJiEgAXH9h3FlWmdzzNU7HJooTyZ3deG9r1VkFm";

    const newItem = { ...item, price: Number(item.price) };

    let config = {
      method: "post",
      baseURL: BASE_URL,
      headers: {
        "X-Parse-Application-Id": APP_ID,
        "X-Parse-REST-API-Key": API_KEY,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(newItem),
    };

    try {
      const response = await axios.request(config);
      dispatch({ type: "ADD_INVOICE_SUCCESS", payload: response.data });
      return response;
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      dispatch({ type: "ADD_INVOICE_ERROR", payload: error.message });
      throw error;
    }
  };
};

export const addInvoiceDetails = (item) => {
  return async (dispatch) => {
    dispatch({ type: "ADD_INVOICE_DETAILS_START" });
    const BASE_URL = "https://parseapi.back4app.com/classes/InvoiceProduct";
    const APP_ID = "jBcsdUCAdIxPdSwIpH7bb0wvUXuSCkZOWd1bY7GX";
    const API_KEY = "rwJiEgAXH9h3FlWmdzzNU7HJooTyZ3deG9r1VkFm";

    const newItem = { ...item, price: Number(item.price) };

    let config = {
      method: "post",
      baseURL: BASE_URL,
      headers: {
        "X-Parse-Application-Id": APP_ID,
        "X-Parse-REST-API-Key": API_KEY,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(newItem),
    };

    try {
      const response = await axios.request(config);
      dispatch({ type: "ADD_INVOICE_DETAILS_SUCCESS", payload: response.data });
      return response;
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      dispatch({ type: "ADD_INVOICE_DETAILS_ERROR", payload: error.message });
      throw error;
    }
  };
};

export const updateItem = (objectId, newQuantity) => {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_ITEM_START" });
    const BASE_URL = `https://parseapi.back4app.com/classes/items/${objectId}`;
    const APP_ID = "jBcsdUCAdIxPdSwIpH7bb0wvUXuSCkZOWd1bY7GX";
    const API_KEY = "rwJiEgAXH9h3FlWmdzzNU7HJooTyZ3deG9r1VkFm";

    const updatedData = { number: newQuantity };

    let config = {
      method: "put",
      baseURL: BASE_URL,
      headers: {
        "X-Parse-Application-Id": APP_ID,
        "X-Parse-REST-API-Key": API_KEY,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(updatedData),
    };

    try {
      const response = await axios.request(config);
      dispatch({ type: "UPDATE_ITEM_SUCCESS", payload: response.data });
      return response;
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      dispatch({ type: "UPDATE_ITEM_ERROR", payload: error.message });
      throw error;
    }
  };
};

export const updateItems = (objectId, updatedData) => {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_ITEMS_START" });
    const URL = `https://parseapi.back4app.com/classes/items/${objectId}`;
    const APP_ID = "jBcsdUCAdIxPdSwIpH7bb0wvUXuSCkZOWd1bY7GX";
    const API_KEY = "rwJiEgAXH9h3FlWmdzzNU7HJooTyZ3deG9r1VkFm";

    const headers = {
      "X-Parse-Application-Id": APP_ID,
      "X-Parse-REST-API-Key": API_KEY,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.put(URL, updatedData, { headers });
      dispatch({ type: "UPDATE_ITEMS_SUCCESS", payload: response.data });
      return response;
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      dispatch({ type: "UPDATE_ITEMS_ERROR", payload: error.message });
      throw error;
    }
  };
};
