import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Input,
  Alert,
  Select,
  Option,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import {
  deleteItems,
  getCategory,
  getItems,
} from "../../../context/actions/getaction";
import { updateItems } from "../../../context/actions/postaction";

const TABLE_HEAD = [
  "الاسم",
  "الباركود",
  "السعر المبيع",
  "السعر الشراء",
  "العدد",
  "الملاحظة",
  "الفئة",
  "التاريخ",
  "تعديل",
  "حذف",
];

const ItemsReport = () => {
  const dispatch = useDispatch();
  const { items, loading, error } = useSelector((state) => state.items);
  const { category } = useSelector((state) => state.category);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Add "الكل" and "على وشك الانتهاء" to the category list dynamically
  const Category = [
    { objectId: "all", name: "الكل" },
    { objectId: "about-to-finish", name: "على وشك الانتهاء" },
    ...category,
  ];

  const [editItem, setEditItem] = useState(null);
  const [editValues, setEditValues] = useState({
    name: "",
    barcode: "",
    sell_price: "",
    puch_price: "",
    number: "",
    note: "",
  });

  useEffect(() => {
    dispatch(getItems());
    dispatch(getCategory());
  }, [dispatch]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    setCurrentPage(1);
  };

  const filteredItems = items.filter((item) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const matchesSearch =
      item.name.toLowerCase().includes(lowerCaseQuery) ||
      item.barcode?.toString().includes(lowerCaseQuery);

    const matchesCategory =
      selectedCategory === "all" // Handle "الكل"
        ? true
        : selectedCategory === "about-to-finish" // Handle "على وشك الانتهاء"
        ? item.number < 6
        : item.category_id?.objectId === selectedCategory;

    return matchesSearch && matchesCategory;
  });

  const sortedItems = filteredItems.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handlePageChange = (newPage) => {
    if (
      newPage > 0 &&
      newPage <= Math.ceil(sortedItems.length / itemsPerPage)
    ) {
      setCurrentPage(newPage);
    }
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteItems(id));
      dispatch(getItems());
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleEditClick = (item) => {
    setEditItem(item.objectId);
    setEditValues({
      name: item.name,
      barcode: item.barcode,
      sell_price: item.sell_price,
      puch_price: item.puch_price,
      number: item.number,
      note: item.note,
    });
  };

  const handleEditChange = (e) => {
    setEditValues({ ...editValues, [e.target.name]: e.target.value });
  };

  const handleEditSubmit = async () => {
    try {
      const updatedValues = {
        name: editValues.name.trim(),
        barcode: editValues.barcode.trim(),
        sell_price: editValues.sell_price.trim(),
        puch_price: editValues.puch_price.trim(),
        number: Number(String(editValues.number || "").trim()),
        note: editValues.note.trim(),
      };

      if (isNaN(updatedValues.number)) {
        throw new Error("Invalid number value");
      }

      await dispatch(updateItems(editItem, updatedValues));
      dispatch(getItems());
      setEditItem(null);
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  return (
    <div className="p-4 lg:p-12">
      <Card dir="rtl" className="p-6 space-y-4">
        <Typography
          variant="h4"
          color="blue-gray"
          className="font-tajawal text-center mb-4"
        >
          المنتجات
        </Typography>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <Input
            color="blue-gray"
            size="lg"
            placeholder="أبحث"
            className="font-tajawal"
            labelProps={{ className: "before:content-none after:content-none" }}
            icon={<MagnifyingGlassIcon className="h-5 w-5" />}
            value={searchQuery}
            onChange={handleSearchChange}
          />

          <div dir="ltr" className="text-right">
            <Select
              label="اختر الفئة"
              color="blue-gray"
              size="lg"
              className="font-tajawal"
              value={selectedCategory}
              onChange={(value) => handleCategoryChange(value)}
            >
              {Category.map((cat, key) => (
                <Option key={key} value={cat.objectId} className="font-tajawal">
                  {cat.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>

        {loading && (
          <Alert className="font-tajawal text-center" color="blue">
            جاري التحميل...
          </Alert>
        )}

        {error && (
          <Alert className="font-tajawal text-center" color="red">
            حدث خطأ: {error}
          </Alert>
        )}

        <CardBody className="overflow-scroll px-0">
          <table className="w-full min-w-max table-auto mx-2">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="px-4 py-2 text-center bg-blue-gray-500 rounded-lg"
                  >
                    <Typography
                      variant="small"
                      color="white"
                      className="font-bold font-tajawal"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentData.map((item) => {
                const Category = category.find(
                  (e) => e.objectId === item.category_id?.objectId
                );

                return (
                  <tr
                    key={item.objectId}
                    className="border-b border-blue-gray-200 hover:bg-blue-gray-100 transition"
                  >
                    <td className="p-4 text-center">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal font-tajawal"
                      >
                        {item.name || " "}
                      </Typography>
                    </td>
                    <td className="p-4 text-center">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal font-tajawal"
                      >
                        {item.barcode || " "}
                      </Typography>
                    </td>
                    <td className="p-4 text-center">
                      <Chip
                        className="text-blue-gray-700 font-tajawal"
                        variant="ghost"
                        size="sm"
                        value={
                          item.sell_price
                            ? item.sell_price.toLocaleString("en-US")
                            : " "
                        }
                        color="green"
                      />
                    </td>
                    <td className="p-4 text-center">
                      <Chip
                        className="text-blue-gray-700 font-tajawal"
                        variant="ghost"
                        size="sm"
                        value={
                          item.puch_price
                            ? item.puch_price.toLocaleString("en-US")
                            : " "
                        }
                        color="red"
                      />
                    </td>
                    <td className="p-4 text-center">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal font-tajawal"
                      >
                        {item.number || " "}
                      </Typography>
                    </td>
                    <td className="p-4 text-center">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal font-tajawal"
                      >
                        {item.note || " "}
                      </Typography>
                    </td>
                    <td className="p-4 text-center">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal font-tajawal"
                      >
                        {Category?.name || " "}
                      </Typography>
                    </td>
                    <td className="p-4 text-center">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal font-tajawal"
                      >
                        {item.createdAt ? formatDate(item.createdAt) : " "}
                      </Typography>
                    </td>
                    <td className="p-4 text-center">
                      <Button
                        color="blue"
                        size="sm"
                        className="font-tajawal"
                        onClick={() => handleEditClick(item)}
                      >
                        تعديل
                      </Button>
                    </td>
                    <td className="p-4 text-center">
                      <Button
                        color="red"
                        size="sm"
                        className="font-tajawal"
                        onClick={() => handleDelete(item.objectId)}
                      >
                        حذف
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>

        {editItem && (
          <div className="mt-6">
            <Typography
              variant="h5"
              color="blue-gray"
              className="font-tajawal text-center mb-4"
            >
              تعديل المنتج
            </Typography>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <Input
                label="الاسم"
                name="name"
                value={editValues.name}
                onChange={handleEditChange}
                className="font-tajawal"
              />
              <Input
                label="الباركود"
                name="barcode"
                value={editValues.barcode}
                onChange={handleEditChange}
                className="font-tajawal"
              />
              <Input
                label="السعر المبيع"
                name="sell_price"
                value={editValues.sell_price}
                onChange={handleEditChange}
                className="font-tajawal"
              />
              <Input
                label="السعر الشراء"
                name="puch_price"
                value={editValues.puch_price}
                onChange={handleEditChange}
                className="font-tajawal"
              />
              <Input
                label="العدد"
                name="number"
                value={editValues.number}
                onChange={handleEditChange}
                className="font-tajawal"
              />
              <Input
                label="الملاحظة"
                name="note"
                value={editValues.note}
                onChange={handleEditChange}
                className="font-tajawal"
              />
            </div>
            <Button
              color="green"
              size="sm"
              className="font-tajawal mt-4"
              onClick={handleEditSubmit}
            >
              حفظ التعديلات
            </Button>
          </div>
        )}

        <CardFooter className="flex items-center justify-between p-4">
          <Typography
            variant="small"
            color="blue-gray"
            className="font-normal font-tajawal"
          >
            صفحة {currentPage} من {Math.ceil(sortedItems.length / itemsPerPage)}
          </Typography>
          <div className="flex gap-2">
            <Button
              className="font-tajawal"
              color="blue-gray"
              variant="outlined"
              size="sm"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              السابق
            </Button>
            <Button
              className="font-tajawal"
              color="blue-gray"
              variant="outlined"
              size="sm"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                currentPage === Math.ceil(sortedItems.length / itemsPerPage)
              }
            >
              التالي
            </Button>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ItemsReport;
