import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Alert, Button } from "@material-tailwind/react";
import {
  getInvoiceDetails,
  getInvoices,
  getClients,
} from "../../../context/actions/getaction";
import InvoiceToPrint from "../form/InvoiceToPrint";
import ReactToPrint from "react-to-print";

const InvoiceDetails = () => {
  const navigate = useNavigate();

  // Retrieve invoice ID from the URL
  const { id } = useParams();
  const dispatch = useDispatch();
  const componentRef = useRef();

  // Fetch invoice, clients, and invoice details on load
  useEffect(() => {
    dispatch(getInvoiceDetails(id));
  }, [dispatch, id]);
  useEffect(() => {
    if (invoices.length === 0) {
      dispatch(getInvoices());
    }
    if (clients.length === 0) {
      dispatch(getClients());
    }
    const fetchInvoiceDetails = async () => {
      try {
        // انتظار عملية جلب تفاصيل الفاتورة
        await dispatch(getInvoiceDetails(id));
      } catch (error) {
        console.error("Error fetching invoice details:", error);
      }
    };

    fetchInvoiceDetails();
  }, [dispatch, id]);

  // Fetch data from Redux store
  const { invoices } = useSelector((state) => state.invoices);
  const { clients } = useSelector((state) => state.clients);
  const { invoiceDetails, loading, error } = useSelector(
    (state) => state.invoiceDetails
  );

  // Find the selected invoice
  const selectedInvoice = invoices.find((inv) => inv.objectId === id);

  // Helper functions
  const getClientNameById = (clientId) => {
    const client = clients.find((client) => client.objectId === clientId);
    return client ? client.name : "غير محدد";
  };

  const getClientPhoneById = (clientId) => {
    const client = clients.find((client) => client.objectId === clientId);
    return client ? client.phone : "غير محدد";
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString();
  };

  const formatProducts = (details) => {
    if (!details || details.length === 0) {
      return []; // If details are not present, return an empty array
    }
    return details.map((detail) => ({
      productId: detail.item.objectId,
      productName: detail.item.name,
      price: Number(detail.price),
      quantity: Number(detail.quantity),
      total: Number(detail.total),
    }));
  };

  if (!selectedInvoice && !loading) {
    return (
      <div className="p-4 lg:p-12">
        <Alert color="red" className="text-center">
          الفاتورة غير موجودة أو حدث خطأ أثناء تحميل البيانات.
        </Alert>
      </div>
    );
  }

  return (
    <div className="p-4 lg:p-12">
      <Card dir="rtl" className="p-6 space-y-4">
        <div className="flex items-center justify-between mb-4">
          <Button
            color="blue-gray"
            className="font-tajawal w-full md:w-40"
            onClick={() => navigate(-1)}
          >
            العودة
          </Button>
          <Typography
            variant="h4"
            color="blue-gray"
            className="font-tajawal text-center"
          >
            تفاصيل الفاتورة
          </Typography>
        </div>

        {loading ? (
          <Alert className="font-tajawal text-center" color="blue">
            جاري تحميل تفاصيل الفاتورة...
          </Alert>
        ) : error ? (
          <Alert className="font-tajawal text-center" color="red">
            حدث خطأ: {error}
          </Alert>
        ) : invoiceDetails && invoiceDetails.length > 0 ? (
          <>
            {/* Display invoice details using InvoiceToPrint */}
            <InvoiceToPrint
              ref={componentRef}
              products={formatProducts(invoiceDetails)}
              clientId={selectedInvoice.user.objectId}
              clientName={getClientNameById(selectedInvoice.user.objectId)}
              phoneNumber={getClientPhoneById(selectedInvoice.user.objectId)}
              date={formatDate(selectedInvoice.date.iso)}
              totalAmount={Number(selectedInvoice.totalAmount)}
              amountPaid={Number(selectedInvoice.amountPaid)}
              remainingAmount={Number(selectedInvoice.remainingAmount)}
              invoiceNumber={selectedInvoice.objectId}
            />

            {/* Print Button */}
            <div className="flex justify-center mt-4">
              <ReactToPrint
                trigger={() => (
                  <Button
                    color="green"
                    className="font-tajawal w-full md:w-auto"
                  >
                    طباعة الفاتورة
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </div>
          </>
        ) : (
          <Alert className="font-tajawal text-center" color="blue">
            لا توجد تفاصيل للفاتورة.
          </Alert>
        )}
      </Card>
    </div>
  );
};

export default InvoiceDetails;
