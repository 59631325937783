import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Input,
  Button,
  Select,
  Option,
  Textarea,
  Alert,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../../context/actions/getaction";
import { addItems } from "../../../context/actions/postaction";

const AddItems = () => {
  const dispatch = useDispatch();
  const { category } = useSelector((state) => state.category);

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [sellPrice, setSellPrice] = useState("");
  const [puchPrice, setPuchPrice] = useState("");
  const [note, setNote] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [barcode, setBarcode] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name && number && sellPrice && puchPrice && categoryId && barcode) {
      const newItem = {
        name,
        number: Number(number), // Convert number to an integer
        sell_price: sellPrice.toString(), // Convert to string
        puch_price: puchPrice.toString(), // Convert to string
        note,
        category_id: {
          __type: "Pointer",
          className: "category",
          objectId: categoryId,
        },
        barcode: barcode.toString(), // Convert to string
      };

      try {
        await dispatch(addItems(newItem)); // Dispatch the addItems action
        setSuccessMessage("تم تسجيل المنتج بنجاح!");
        setErrorMessage("");
        // Clear form fields
        setName("");
        setNumber("");
        setSellPrice("");
        setPuchPrice("");
        setNote("");
        setCategoryId("");
        setBarcode("");
      } catch (error) {
        setErrorMessage("حدث خطأ أثناء تسجيل المنتج. يرجى المحاولة مرة أخرى.");
        setSuccessMessage("");
      }
    } else {
      setErrorMessage("يرجى ملء جميع الحقول المطلوبة.");
      setSuccessMessage("");
    }
  };

  return (
    <div className="p-4 lg:p-12">
      <Card className="p-6 space-y-4" dir="rtl">
        <Typography
          variant="h4"
          color="blue-gray"
          className="font-tajawal text-center mb-4"
        >
          تسجيل منتج جديد
        </Typography>

        {successMessage && (
          <Alert color="green" className="font-tajawal text-center">
            {successMessage}
          </Alert>
        )}

        {errorMessage && (
          <Alert color="red" className="font-tajawal text-center">
            {errorMessage}
          </Alert>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Grid layout for larger screens */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <Input
              label="اسم المنتج"
              color="blue-gray"
              size="lg"
              className="font-tajawal"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            <Input
              label="العدد"
              inputMode="numeric"
              color="blue-gray"
              size="lg"
              className="font-tajawal"
              type="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              required
            />

            <Input
              label="سعر المبيع"
              inputMode="numeric"
              color="blue-gray"
              size="lg"
              className="font-tajawal"
              type="number"
              value={sellPrice}
              onChange={(e) => setSellPrice(e.target.value)}
              required
            />

            <Input
              label="سعر الشراء"
              inputMode="numeric"
              color="blue-gray"
              size="lg"
              className="font-tajawal"
              type="number"
              value={puchPrice}
              onChange={(e) => setPuchPrice(e.target.value)}
              required
            />

            <Select
              label="اختر الفئة"
              color="blue-gray"
              size="lg"
              className="font-tajawal"
              value={categoryId}
              onChange={(value) => setCategoryId(value)}
              required
            >
              {category.map((cat) => (
                <Option key={cat.objectId} value={cat.objectId}>
                  {cat.name}
                </Option>
              ))}
            </Select>

            <Input
              inputMode="numeric"
              label="الباركود"
              color="blue-gray"
              size="lg"
              className="font-tajawal"
              value={barcode}
              onChange={(e) => setBarcode(e.target.value)}
              required
            />
          </div>

          {/* Note field outside grid */}
          <Textarea
            label="ملاحظة"
            color="blue-gray"
            size="lg"
            className="font-tajawal"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />

          <Button
            type="submit"
            color="blue"
            className="font-tajawal w-full lg:w-auto lg:self-end"
          >
            تسجيل
          </Button>
        </form>
      </Card>
    </div>
  );
};

export default AddItems;
