import {
  Card,
  Input,
  Button,
  Typography,
  Alert,
  Select,
  Option,
  CardBody,
} from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { addProduct } from "../../../context/actions/postaction";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getProduct } from "../../../context/actions/getaction";

export default function AddForm() {
  const { id } = useParams();
  const { clients } = useSelector((state) => state.clients);
  const user = clients.filter((e) => e?.objectId === id);
  const [note, setNote] = useState("");
  const [type, setType] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (success !== null) {
      const timer = setTimeout(() => {
        setSuccess(null);
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!note || !type || !price || !currency) {
      setError("جميع الحقول مطلوبة.");
      setSuccess(false);
      return;
    }

    try {
      const response = await dispatch(
        addProduct({
          note,
          type, // Send `type` as a string
          price,
          currency,
          user_id: { __type: "Pointer", className: "users", objectId: id },
        })
      );

      if (response.status === 201) {
        setSuccess(true);
        setNote("");
        setType(""); // Reset `type` field
        setPrice("");
        setCurrency("");
        dispatch(getProduct());
        navigate(`/reports/${id}`);
      } else {
        setSuccess(false);
        setError("حدث خطأ أثناء إرسال النموذج.");
      }
    } catch (error) {
      setSuccess(false);
      setError("حدث خطأ أثناء إرسال النموذج.");
    }
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <Card className="shadow-lg shadow-[#071975] rounded-2xl black-foto border-none ">
          <CardBody className="py-4 px-8">
            <div className="flex justify-center items-center ">
              <Typography className="font-bold  text-white font-tajawal">
                {user[0].name}
              </Typography>
            </div>
          </CardBody>
        </Card>
      </div>
      <Card className="items-center" color="transparent" shadow={false}>
        <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
          {success === true ? (
            <div dir="rtl" className="flex w-full flex-col gap-2">
              <Alert className="font-tajawal text-center" color="green">
                تم حفظ البيانات بنجاح.
              </Alert>
            </div>
          ) : success === false && error ? (
            <div dir="rtl" className="flex w-full flex-col gap-2">
              <Alert className="font-tajawal items-center" color="red">
                {error}
              </Alert>
            </div>
          ) : null}
          <div className="mb-1 flex flex-col gap-6">
            <Typography
              variant="h6"
              color="white"
              className="-mb-3 font-tajawal text-right"
            >
              الملاحظة
            </Typography>
            <Input
              value={note}
              onChange={(e) => setNote(e.target.value)}
              color="white"
              size="lg"
              placeholder="الملاحظة"
              className="!border-t-blue-gray-200 font-tajawal text-right"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />
            <Typography
              variant="h6"
              color="white"
              className="-mb-3 font-tajawal text-right"
            >
              المبلغ
            </Typography>
            <Input
              type="number"
              inputMode="numeric"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              color="white"
              size="lg"
              placeholder="المبلغ"
              className="!border-t-blue-gray-200 font-tajawal text-right"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />

            <Typography
              variant="h6"
              color="white"
              className="-mb-3 font-tajawal text-right"
            >
              العملة
            </Typography>
            <Select
              onChange={(value) => {
                setCurrency(value);
              }}
              value={currency}
              className="font-tajawal text-white"
              label="العملة"
              labelProps={{
                className: "font-tajawal",
              }}
            >
              <Option value="USD" className="font-tajawal">
                USD
              </Option>
              <Option value="IQD" className="font-tajawal">
                IQD
              </Option>
            </Select>

            <Typography
              variant="h6"
              color="white"
              className="-mb-3 font-tajawal text-right"
            >
              الحاله
            </Typography>
            <Select
              onChange={(value) => {
                setType(Number(value)); // Convert selected value to a number
              }}
              value={type}
              className="font-tajawal text-white"
              label="الحالة"
              labelProps={{
                className: "font-tajawal",
              }}
            >
              <Option value="0" className="font-tajawal">
                له
              </Option>
              <Option value="1" className="font-tajawal">
                عليه
              </Option>
            </Select>
          </div>
          <Button
            onClick={handleSubmit}
            className="mt-6 font-tajawal text-lg bg-[#6495ed]"
            fullWidth
          >
            حفظ
          </Button>
        </form>
      </Card>
    </div>
  );
}
