import React, { useState } from "react";
import {
  Card,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Input,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { PlusIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { deleteProduct, getProduct } from "../../../context/actions/getaction";

const TABLE_HEAD = [
  "الملاحظة",
  "المبلغ",
  "العمله",
  "الحاله",
  "التاريخ",
  "تعديل / حذف",
];

function AddReports() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { product } = useSelector((state) => state.product);
  const { clients } = useSelector((state) => state.clients);
  const user = clients.filter((e) => e?.objectId === id);
  const data = product.filter((e) => e.user_id?.objectId === id);
  const USD1 = data.filter((e) => e.currency === "USD" && e.type == 0);
  const USD2 = data.filter((e) => e.currency === "USD" && e.type == 1);
  const totalUSD1 = USD1.reduce((sum, e) => sum + e.price, 0);
  const totalUSD2 = USD2.reduce((sum, e) => sum + e.price, 0);
  const totalUSD = totalUSD1 - totalUSD2;
  const IQD1 = data.filter((e) => e.currency === "IQD" && e.type == 0);
  const IQD2 = data.filter((e) => e.currency === "IQD" && e.type == 1);
  const totalIQD1 = IQD1.reduce((sum, e) => sum + e.price, 0);
  const totalIQD2 = IQD2.reduce((sum, e) => sum + e.price, 0);
  const totalIQD = totalIQD1 - totalIQD2;

  // حالة البحث
  const [searchQuery, setSearchQuery] = useState("");

  // إدارة حالة الصفحة الحالية
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // دالة التحكم في البحث
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  // تصفية البيانات بناءً على البحث
  const filteredData = data.filter((item) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      item.currency.toLowerCase().includes(lowerCaseQuery) ||
      item.note?.toLowerCase().includes(lowerCaseQuery) ||
      item.price?.toString().includes(lowerCaseQuery)
    );
  });

  // ترتيب البيانات حسب التاريخ الأحدث
  const sortedData = filteredData.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  // حساب البيانات المعروضة بناءً على الصفحة الحالية
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // دالة للتنقل بين الصفحات
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= Math.ceil(sortedData.length / itemsPerPage)) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <Card className="shadow-lg shadow-[#071975] rounded-2xl black-foto border-none ">
          <CardBody className="py-4 px-8">
            <div className="flex justify-center items-center ">
              <Typography className="font-bold  text-white font-tajawal">
                {user[0].name}
              </Typography>
            </div>
          </CardBody>
        </Card>
      </div>
      <Card dir="rtl" className="black-foto ">
        <div className="flex items-center justify-between gap-8 pt-4 ">
          <div className="flex gap-12 px-2">
            <Link to={`/add_Form/${id}`}>
              <Button
                color="blue"
                className="flex items-center gap-3 font-tajawal"
                size="sm"
              >
                <PlusIcon strokeWidth={2} className="h-4 w-4 " />
                إضافة
              </Button>
            </Link>
            <span className="font-tajawal text-white">
              IQD : {totalIQD.toLocaleString("en-US")}
            </span>
            <span className="font-tajawal text-white">
              USD : {totalUSD.toLocaleString("en-US")}
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between w-full md:w-[20%] gap-8 p-2 pr-2">
          <Input
            color="white"
            size="lg"
            placeholder="أبحث"
            className="!border-t-blue-gray-200 font-tajawal text-right"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            icon={<MagnifyingGlassIcon className="h-5 w-5 " />}
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <CardBody className="overflow-scroll px-0">
          <table className="w-full min-w-max table-auto">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th key={head} className="px-4 pb-4 text-center ">
                    <Typography
                      variant="small"
                      color="white"
                      className="font-bold leading-none opacity-70 font-tajawal bg-[#253151] p-2 rounded-lg"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, index) => {
                const isLast = index === currentData.length - 1;
                const classes = "p-4 border-r-2 border-[#344369] text-center ";

                return (
                  <tr key={item.objectId}>
                    <td
                      className={`${
                        index === 0 ? "border-t-2 border-[#344369]" : ""
                      }`}
                    >
                      <Typography
                        variant="small"
                        color="white"
                        className="font-normal font-tajawal text-center"
                      >
                        {item.note}
                      </Typography>
                    </td>
                    <td
                      className={`${
                        index === 0 ? "border-t-2 border-[#344369]" : ""
                      } ${classes}`}
                    >
                      <div className="text-center">
                        <Chip
                          className="text-white font-tajawal text-center"
                          variant="ghost"
                          size="sm"
                          value={(item.price >= 0
                            ? item.price
                            : 0
                          ).toLocaleString("en-US")}
                          color={item.type == 0 ? "green" : "red"}
                        />
                      </div>
                    </td>
                    <td
                      className={`${
                        index === 0 ? "border-t-2 border-[#344369]" : ""
                      } ${classes}`}
                    >
                      <Typography
                        variant="small"
                        color="white"
                        className="font-normal font-tajawal text-center"
                      >
                        {item.currency}
                      </Typography>
                    </td>
                    <td
                      className={`${
                        index === 0 ? "border-t-2 border-[#344369]" : ""
                      } ${classes}`}
                    >
                      <div className="text-center">
                        <Chip
                          className="text-white font-tajawal text-center"
                          variant="ghost"
                          size="sm"
                          value={item.type == 0 ? "له" : "عليه"}
                          color={item.type == 0 ? "green" : "red"}
                        />
                      </div>
                    </td>
                    <td
                      className={`${
                        index === 0 ? "border-t-2 border-[#344369]" : ""
                      } ${classes}`}
                    >
                      <div className="flex flex-col text-center">
                        <Typography
                          as="div"
                          variant="small"
                          color="white"
                          className="font-normal font-tajawal"
                        >
                          {formatDate(item.createdAt)}
                        </Typography>
                      </div>
                    </td>
                    <td
                      className={`${
                        index === 0 ? "border-t-2 border-[#344369]" : ""
                      } ${classes}`}
                    >
                      <div className="flex flex-col text-center">
                        <Typography
                          as="div"
                          variant="small"
                          color="white"
                          className="font-normal font-tajawal"
                        >
                          <Button
                            onClick={async () => {
                              await dispatch(deleteProduct(item.objectId));
                              dispatch(getProduct());
                            }}
                            color="red"
                            className="flex items-center gap-3 font-tajawal"
                            size="sm"
                          >
                            حذف
                          </Button>
                        </Typography>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between p-4">
          <Typography
            variant="small"
            color="white"
            className="font-normal font-tajawal"
          >
            صفحة {currentPage} من {Math.ceil(sortedData.length / itemsPerPage)}
          </Typography>
          <div className="flex gap-2">
            <Button
              className="font-tajawal"
              color="white"
              variant="outlined"
              size="sm"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              السابق
            </Button>
            <Button
              className="font-tajawal"
              color="white"
              variant="outlined"
              size="sm"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                currentPage === Math.ceil(sortedData.length / itemsPerPage)
              }
            >
              التالي
            </Button>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}

export default AddReports;
