import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  Typography,
  Input,
  Button,
  Alert,
  IconButton,
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getItems,
  getClients,
  getCategory,
} from "../../../context/actions/getaction";
import { Autocomplete, TextField } from "@mui/material";
import { TrashIcon } from "@heroicons/react/24/outline";
import ReactToPrint from "react-to-print";
import InvoiceToPrint from "./InvoiceToPrint";
import {
  addInvoice,
  addInvoiceDetails,
  addItems,
  addProduct,
  addUser,
  updateItem,
} from "../../../context/actions/postaction";

const AddInvoice = () => {
  const { category } = useSelector((state) => state.category);
  const { items } = useSelector((state) => state.items);
  const { clients } = useSelector((state) => state.clients);
  const dispatch = useDispatch();

  const componentRef = useRef();
  const reactToPrintRef = useRef();

  useEffect(() => {
    dispatch(getItems());
    dispatch(getClients());
    dispatch(getCategory());
  }, [dispatch]);

  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [isNewClient, setIsNewClient] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);

  const [products, setProducts] = useState([
    {
      productId: "",
      productName: "",
      price: 0,
      quantity: 1,
      total: 0,
      available: 0,
      isNewProduct: false,
      categoryId: "",
      barcode: "",
      note: "",
      number: 0, // Adding the 'number' field to products
    },
  ]);

  const [amountPaid, setAmountPaid] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [invoiceId, setInvoiceId] = useState(null);

  const totalAmount = products.reduce(
    (sum, product) => sum + product.price * product.quantity,
    0
  );
  const remainingAmount = totalAmount - amountPaid;

  const [isNewProductModalOpen, setIsNewProductModalOpen] = useState(false);
  const [newProduct, setNewProduct] = useState({
    name: "",
    sell_price: 0,
    puch_price: 0,
    note: "",
    category_id: "",
    barcode: "",
    number: 0, // Adding 'number' field to new product modal
  });

  const handleClientChange = (client) => {
    if (client === null) {
      setIsNewClient(true);
      setClientId("");
      setClientName("");
      setPhoneNumber("");
    } else {
      setIsNewClient(false);
      setClientId(client.objectId);
      setClientName(client.name);
      setPhoneNumber(client.phone);
    }
  };

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...products];
    if (field === "isNewProduct") {
      updatedProducts[index].isNewProduct = value;
      updatedProducts[index].productName = "";
      updatedProducts[index].price = 0;
      updatedProducts[index].quantity = 1;
      updatedProducts[index].total = 0;
      updatedProducts[index].categoryId = "";
      updatedProducts[index].barcode = "";
      updatedProducts[index].note = "";
      updatedProducts[index].number = 0; // Resetting number field
    } else if (field === "productId") {
      const selectedProduct = items.find((item) => item.objectId === value);
      if (selectedProduct) {
        updatedProducts[index].isNewProduct = false;
        updatedProducts[index].productId = selectedProduct.objectId;
        updatedProducts[index].productName = selectedProduct.name;
        updatedProducts[index].price = selectedProduct.sell_price;
        updatedProducts[index].quantity = 1;
        updatedProducts[index].total = selectedProduct.sell_price * 1;
        updatedProducts[index].categoryId =
          selectedProduct.category_id?.objectId;
        updatedProducts[index].barcode = selectedProduct.barcode || "";
        updatedProducts[index].note = selectedProduct.note || "";
        updatedProducts[index].available = selectedProduct.number;
        updatedProducts[index].number = selectedProduct.number; // Setting number field
      }
    } else if (field === "price") {
      updatedProducts[index].price = parseFloat(value) || 0;
      updatedProducts[index].total =
        updatedProducts[index].price * updatedProducts[index].quantity;
    } else if (field === "quantity") {
      const newQuantity = parseInt(value) || 1;
      if (newQuantity > updatedProducts[index].available) {
        setErrorMessage("الكمية غير كافية في المخزون.");
        return;
      }
      updatedProducts[index].quantity = newQuantity;
      updatedProducts[index].total =
        updatedProducts[index].price * updatedProducts[index].quantity;
      setErrorMessage("");
    }
    setProducts(updatedProducts);
  };

  const handleAddProduct = () => {
    setProducts([
      ...products,
      {
        productId: "",
        productName: "",
        price: 0,
        quantity: 1,
        total: 0,
        available: 0,
        isNewProduct: false,
        categoryId: "",
        barcode: "",
        note: "",
        number: 0, // Adding number field for new product
      },
    ]);
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = products.filter((_, idx) => idx !== index);
    setProducts(updatedProducts);
  };

  const handleOpenNewProductModal = () => {
    setIsNewProductModalOpen(true);
  };

  const handleCloseNewProductModal = () => {
    setIsNewProductModalOpen(false);
    setNewProduct({
      name: "",
      sell_price: 0,
      puch_price: 0,
      note: "",
      category_id: "",
      barcode: "",
      number: 0, // Resetting number field in modal
    });
  };

  const handleNewProductChange = (field, value) => {
    if (field === "number") {
      setNewProduct({ ...newProduct, [field]: parseInt(value, 10) || 0 });
    } else {
      setNewProduct({ ...newProduct, [field]: value });
    }
  };

  const handleAddNewProduct = async () => {
    try {
      const newProductData = {
        name: newProduct.name,
        sell_price: newProduct.sell_price,
        puch_price: newProduct.puch_price,
        note: newProduct.note,
        category_id: {
          __type: "Pointer",
          className: "category",
          objectId: newProduct.category_id,
        },
        barcode: newProduct.barcode,
        number: parseInt(newProduct.number, 10), // Ensure number is sent as an integer
      };

      const productResponse = await dispatch(addItems(newProductData));
      const productId = productResponse.data.objectId;
      dispatch(getItems());

      setProducts([
        ...products,
        {
          productId: productId,
          productName: newProduct.name,
          price: newProduct.sell_price,
          quantity: 1,
          total: newProduct.sell_price,
          available: newProduct.number, // Already parsed as a number
          isNewProduct: false,
          categoryId: newProduct.category_id,
          barcode: newProduct.barcode,
          note: newProduct.note,
        },
      ]);

      handleCloseNewProductModal();
    } catch (error) {
      setErrorMessage("حدث خطأ أثناء إضافة المنتج الجديد.");
    }
  };

  const handleSubmitAndPrint = async (e) => {
    e.preventDefault();
    if (
      (!isNewClient && !clientId && (!clientName || !phoneNumber)) ||
      products.some((product) => !product.productId && !product.isNewProduct)
    ) {
      setErrorMessage("يرجى ملء جميع الحقول المطلوبة.");
      setSuccessMessage("");
      return;
    }

    try {
      let currentClientId = clientId;
      if (isNewClient) {
        const newClientData = {
          name: clientName,
          phone: phoneNumber,
          date: new Date().toISOString(),
        };
        const clientResponse = await dispatch(addUser(newClientData));
        currentClientId = clientResponse.data.objectId;
        setClientId(currentClientId);
      }

      const invoiceData = {
        user: {
          __type: "Pointer",
          className: "users",
          objectId: currentClientId,
        },
        clientId: currentClientId,
        date: {
          __type: "Date",
          iso: new Date(date).toISOString(),
        },
        totalAmount: Number(totalAmount),
        amountPaid: Number(amountPaid),
        remainingAmount: Math.abs(Number(remainingAmount)),
      };

      const invoiceResponse = await dispatch(addInvoice(invoiceData));
      const newInvoiceId = invoiceResponse.data.objectId;
      setInvoiceId(newInvoiceId);

      if (remainingAmount !== 0) {
        const remainingData = {
          note: "المبلغ المتبقي من الفاتورة",
          type: "1",
          price: Math.abs(Number(remainingAmount)),
          currency: "IQD",
          user_id: {
            __type: "Pointer",
            className: "users",
            objectId: currentClientId,
          },
        };
        await dispatch(addProduct(remainingData));
      }

      for (const product of products) {
        let productId = product.productId;
        if (product.isNewProduct) {
          const newProductData = {
            name: product.productName,
            sell_price: product.price,
            category_id: {
              __type: "Pointer",
              className: "category",
              objectId: product.categoryId,
            },
            barcode: product.barcode,
            note: product.note,
            number: product.number, // Including number field for new product
          };
          const productResponse = await dispatch(addProduct(newProductData));
          productId = productResponse.data.objectId;
        }

        const productData = {
          invoice: {
            __type: "Pointer",
            className: "invoice",
            objectId: newInvoiceId,
          },
          item: {
            __type: "Pointer",
            className: "items",
            objectId: productId,
          },
          quantity: product.quantity,
          price: product.price,
          total: product.total,
        };

        await dispatch(addInvoiceDetails(productData));

        if (!product.isNewProduct) {
          const updatedQuantity = product.available - product.quantity;
          await dispatch(updateItem(product.productId, updatedQuantity));
        }
      }

      setSuccessMessage("تم إنشاء الفاتورة بنجاح!");
      setErrorMessage("");

      setTimeout(() => {
        if (reactToPrintRef.current) {
          reactToPrintRef.current.handlePrint();
        }
        setClientId("");
        setClientName("");
        setPhoneNumber("");
        setIsNewClient(false);
        setDate(new Date().toISOString().split("T")[0]);
        setProducts([
          {
            productId: "",
            productName: "",
            price: 0,
            quantity: 1,
            total: 0,
            available: 0,
            isNewProduct: false,
            categoryId: "",
            barcode: "",
            note: "",
            number: 0, // Resetting number field
          },
        ]);
        setAmountPaid(0);
        dispatch(getItems());
        dispatch(getClients());
        dispatch(getCategory());
      }, 500);
    } catch (error) {
      setErrorMessage("حدث خطأ أثناء إنشاء الفاتورة. يرجى المحاولة مرة أخرى.");
      setSuccessMessage("");
    }
  };

  return (
    <div className="p-4 lg:p-12">
      <Card className="p-6 space-y-6 font-tajawal" dir="rtl">
        <Typography
          variant="h4"
          color="blue-gray"
          className="text-center mb-6 font-tajawal"
        >
          إنشاء فاتورة جديدة
        </Typography>

        {successMessage && (
          <Alert color="green" className="text-center">
            {successMessage}
          </Alert>
        )}

        {errorMessage && (
          <Alert color="red" className="text-center">
            {errorMessage}
          </Alert>
        )}

        <form onSubmit={handleSubmitAndPrint} className="space-y-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div>
              <Autocomplete
                options={[{ name: "عميل جديد", objectId: "new" }, ...clients]}
                getOptionLabel={(option) => `${option.name}`}
                onChange={(event, value) => {
                  if (!value || value.objectId === "new") {
                    handleClientChange(null);
                  } else {
                    handleClientChange(value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="اختيار العميل"
                    variant="outlined"
                    required
                    InputProps={{
                      ...params.InputProps,
                      className: "font-tajawal",
                      style: {
                        fontSize: "16px",
                        textAlign: "center",
                        fontFamily: "Tajawal",
                      },
                    }}
                    InputLabelProps={{
                      className: "font-tajawal",
                      style: { fontSize: "16px", fontFamily: "Tajawal" },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 40,
                      },
                    }}
                  />
                )}
                noOptionsText="لا توجد خيارات"
                componentsProps={{
                  popper: {
                    sx: {
                      "& .MuiAutocomplete-listbox": {
                        fontFamily: "Tajawal",
                        fontSize: "16px",
                      },
                    },
                  },
                }}
              />
            </div>

            {isNewClient && (
              <div dir="ltr">
                <Input
                  label="اسم العميل الجديد"
                  color="blue-gray"
                  size="md"
                  className="font-tajawal text-right"
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                  required
                />
              </div>
            )}

            <div dir="ltr">
              <Input
                label="رقم الهاتف"
                inputMode="numeric"
                color="blue-gray"
                size="md"
                className="font-tajawal text-right"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                lang="en"
              />
            </div>

            <div dir="ltr">
              <Input
                label="التاريخ"
                type="date"
                color="blue-gray"
                size="md"
                className="font-tajawal text-right"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                lang="en"
              />
            </div>
          </div>

          <Typography variant="h5" className="font-tajawal mt-8">
            المنتجات
          </Typography>

          {products.map((product, index) => {
            return (
              <div
                key={index}
                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6 items-end"
              >
                <div>
                  <Autocomplete
                    options={[{ name: "منتج جديد", objectId: "new" }, ...items]}
                    getOptionLabel={(option) => {
                      const productCategory = category.find(
                        (cat) => cat.objectId === option.category_id?.objectId
                      );
                      return `${option.name} (${
                        productCategory ? productCategory.name : "بدون فئة"
                      })`;
                    }}
                    onChange={(event, value) => {
                      if (value && value.objectId === "new") {
                        handleOpenNewProductModal();
                      } else {
                        handleProductChange(
                          index,
                          "productId",
                          value ? value.objectId : ""
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="اسم المنتج"
                        variant="outlined"
                        required
                        InputProps={{
                          ...params.InputProps,
                          className: "font-tajawal",
                          style: {
                            fontSize: "16px",
                            textAlign: "center",
                            fontFamily: "Tajawal",
                          },
                        }}
                        InputLabelProps={{
                          className: "font-tajawal",
                          style: { fontSize: "16px", fontFamily: "Tajawal" },
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: 40,
                          },
                        }}
                      />
                    )}
                    noOptionsText="لا توجد منتجات"
                    componentsProps={{
                      popper: {
                        sx: {
                          "& .MuiAutocomplete-listbox": {
                            fontFamily: "Tajawal",
                            fontSize: "16px",
                          },
                        },
                      },
                    }}
                  />
                </div>

                <div dir="ltr">
                  <Input
                    label="السعر"
                    color="blue-gray"
                    size="md"
                    className="font-tajawal text-right"
                    value={product.price}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) =>
                      handleProductChange(index, "price", e.target.value)
                    }
                    required
                    lang="en"
                  />
                </div>

                <div dir="ltr">
                  <Input
                    label="الكمية"
                    inputMode="numeric"
                    color="blue-gray"
                    size="md"
                    className="font-tajawal text-right"
                    value={product.quantity}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) =>
                      handleProductChange(index, "quantity", e.target.value)
                    }
                    required
                    lang="en"
                  />
                </div>

                <div dir="ltr">
                  <Input
                    label="السعر الإجمالي"
                    color="blue-gray"
                    size="md"
                    className="font-tajawal text-right"
                    value={product.total.toLocaleString("en-US")}
                    readOnly
                    lang="en"
                  />
                </div>

                <IconButton
                  variant="text"
                  color="red"
                  onClick={() => handleRemoveProduct(index)}
                >
                  <TrashIcon className="h-5 w-5" />
                </IconButton>
              </div>
            );
          })}

          <Button
            color="blue"
            variant="outlined"
            onClick={handleAddProduct}
            className="font-tajawal"
          >
            إضافة منتج آخر
          </Button>

          <Typography variant="h5" className="font-tajawal mt-8">
            المجموع
          </Typography>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div dir="ltr">
              <Input
                label="المجموع الكلي"
                type="text"
                color="blue-gray"
                size="md"
                className="font-tajawal text-right"
                value={totalAmount.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}
                readOnly
                lang="en"
              />
            </div>

            <div dir="ltr">
              <Input
                inputMode="numeric"
                label="المبلغ المسدد"
                color="blue-gray"
                size="md"
                className="font-tajawal text-right"
                value={amountPaid}
                onFocus={(e) => e.target.select()}
                onChange={(e) => setAmountPaid(e.target.value)}
                required
                lang="en"
              />
            </div>

            <div dir="ltr">
              <Input
                label="الباقي"
                type="text"
                color="blue-gray"
                size="md"
                className="font-tajawal text-right"
                value={remainingAmount.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}
                readOnly
                lang="en"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-6 mt-8">
            <Button
              type="submit"
              color="blue"
              className="font-tajawal w-full md:w-auto"
            >
              إنشاء الفاتورة والطباعة
            </Button>
          </div>
        </form>
      </Card>

      {/* قسم الفاتورة القابلة للطباعة */}
      <div className="hidden">
        <InvoiceToPrint
          ref={componentRef}
          products={products}
          clientId={clientId}
          clientName={clientName}
          phoneNumber={phoneNumber}
          date={date}
          totalAmount={totalAmount}
          amountPaid={amountPaid}
          remainingAmount={remainingAmount}
          invoiceNumber={invoiceId}
        />
      </div>

      <ReactToPrint
        ref={reactToPrintRef}
        content={() => componentRef.current}
      />

      {/* نافذة الـ Modal لإضافة المنتج الجديد */}
      <Dialog open={isNewProductModalOpen} handler={handleCloseNewProductModal}>
        <DialogHeader>إضافة منتج جديد</DialogHeader>
        <DialogBody divider>
          <Input
            label="اسم المنتج"
            value={newProduct.name}
            onChange={(e) => handleNewProductChange("name", e.target.value)}
            required
          />
          <Input
            label="سعر البيع"
            value={newProduct.sell_price}
            onChange={(e) =>
              handleNewProductChange("sell_price", e.target.value)
            }
            required
          />
          <Input
            label="سعر الشراء"
            value={newProduct.puch_price}
            onChange={(e) =>
              handleNewProductChange("puch_price", e.target.value)
            }
            required
          />
          <Input
            label="الباركود"
            value={newProduct.barcode}
            onChange={(e) => handleNewProductChange("barcode", e.target.value)}
          />
          <Input
            label="الملاحظات"
            value={newProduct.note}
            onChange={(e) => handleNewProductChange("note", e.target.value)}
          />
          <Input
            label="العدد"
            value={newProduct.number}
            onChange={(e) => handleNewProductChange("number", e.target.value)}
            required
          />
          <Autocomplete
            options={category}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              handleNewProductChange(
                "category_id",
                value ? value.objectId : ""
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="فئة المنتج"
                variant="outlined"
                required
              />
            )}
            PopperProps={{
              style: { zIndex: 1300 },
            }}
            disablePortal
          />
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleCloseNewProductModal}
          >
            إلغاء
          </Button>
          <Button variant="text" color="blue" onClick={handleAddNewProduct}>
            إضافة المنتج
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default AddInvoice;
