import {
  Card,
  Input,
  Button,
  Typography,
  Alert,
  Select,
  Option,
} from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { addUser } from "../../../context/actions/postaction";
import { useDispatch } from "react-redux";

export default function Form() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const date = `${year}-${month}-${day}`; // تأكد من أن التاريخ يتم إرساله كـ String

  useEffect(() => {
    if (success !== null) {
      const timer = setTimeout(() => {
        setSuccess(null);
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!name || !phone || !date) {
      setError("جميع الحقول مطلوبة.");
      setSuccess(false);
      return;
    }

    try {
      const response = await dispatch(
        addUser({
          name,
          phone: Number(phone), // تأكد من إرسال رقم الهاتف كـ Number
          date, // تأكد من إرسال التاريخ كـ String
        })
      );

      if (response.status === 201) {
        setSuccess(true);
        setName("");
        setPhone("");
        setDay("");
        setMonth("");
        setYear("");
      } else {
        setSuccess(false);
        setError("حدث خطأ أثناء إرسال النموذج.");
      }
    } catch (error) {
      setSuccess(false);
      setError("حدث خطأ أثناء إرسال النموذج.");
    }
  };

  const days = Array.from({ length: 31 }, (_, i) => (i + 1).toString());
  const months = Array.from({ length: 12 }, (_, i) => (i + 1).toString());
  return (
    <Card className="items-center" color="transparent" shadow={false}>
      <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
        {success === true ? (
          <div dir="rtl" className="flex w-full flex-col gap-2">
            <Alert className="font-tajawal text-center" color="green">
              تم حفظ البيانات بنجاح.
            </Alert>
          </div>
        ) : success === false && error ? (
          <div dir="rtl" className="flex w-full flex-col gap-2">
            <Alert className="font-tajawal items-center" color="red">
              {error}
            </Alert>
          </div>
        ) : null}
        <div className="mb-1 flex flex-col gap-6">
          <Typography
            variant="h6"
            color="white"
            className="-mb-3 font-tajawal text-right"
          >
            الأسم
          </Typography>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            color="white"
            size="lg"
            placeholder="الأسم"
            className="!border-t-blue-gray-200 font-tajawal text-right"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
          />
          <Typography
            variant="h6"
            color="white"
            className="-mb-3 font-tajawal text-right"
          >
            رقم الهاتف
          </Typography>
          <Input
            type="number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            color="white"
            size="lg"
            placeholder="رقم الهاتف"
            className="!border-t-blue-gray-200 font-tajawal text-right"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
          />
          <Typography
            variant="h6"
            color="white"
            className="-mb-3 font-tajawal text-right"
          >
            التاريخ
          </Typography>
          <div className="flex flex-col gap-4">
            <Select
              value={day}
              onChange={(value) => setDay(value)}
              className="font-tajawal text-white !border-t-blue-gray-200"
              label="اليوم"
              labelProps={{
                className: "font-tajawal",
              }}
            >
              {days.map((day) => (
                <Option key={day} value={day}>
                  {day}
                </Option>
              ))}
            </Select>

            <Select
              value={month}
              onChange={(value) => setMonth(value)}
              className="font-tajawal text-white !border-t-blue-gray-200"
              label="الشهر"
              labelProps={{
                className: "font-tajawal",
              }}
            >
              {months.map((month) => (
                <Option key={month} value={month}>
                  {month}
                </Option>
              ))}
            </Select>

            <Input
              value={year}
              onChange={(e) => setYear(e.target.value)}
              color="white"
              size="lg"
              placeholder="السنة"
              className="!border-t-blue-gray-200 font-tajawal text-right"
              label="السنه"
              labelProps={{
                className: "font-tajawal",
              }}
            />
          </div>
        </div>
        <Button
          onClick={handleSubmit}
          className="mt-6 font-tajawal text-lg bg-[#6495ed]"
          fullWidth
        >
          حفظ
        </Button>
      </form>
    </Card>
  );
}
