const initialAddItemState = {
  loading: false,
  item: {},
  error: null,
};

export const reducerAddItem = (state = initialAddItemState, action) => {
  switch (action.type) {
    case "ADD_ITEM_START":
      return { ...state, loading: true, error: null };
    case "ADD_ITEM_SUCCESS":
      return { ...state, loading: false, item: action.payload };
    case "ADD_ITEM_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialAddUser = {
  loading: false,
  user: {},
  error: null,
};

export const reducerAddUser = (state = initialAddUser, action) => {
  switch (action.type) {
    case "ADD_USER_START":
      return { ...state, loading: true, error: null };
    case "ADD_USER_SUCCESS":
      return { ...state, loading: false, user: action.payload };
    case "ADD_USER_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialAddProduct = {
  loading: false,
  product: {},
  error: null,
};

export const reducerAddProduct = (state = initialAddProduct, action) => {
  switch (action.type) {
    case "ADD_PRODUCT_START":
      return { ...state, loading: true, error: null };
    case "ADD_PRODUCT_SUCCESS":
      return { ...state, loading: false, product: action.payload };
    case "ADD_PRODUCT_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialDeleteroduct = {
  loading: false,
  Delete: {},
  error: null,
};

export const reducerDeleteProduct = (state = initialDeleteroduct, action) => {
  switch (action.type) {
    case "DELETE_PRODUCT_START":
      return { ...state, loading: true, error: null };
    case "DELETE_PRODUCT_SUCCESS":
      return { ...state, loading: false, product: action.payload };
    case "DELETE_PRODUCT_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialAddItems = {
  loading: false,
  items: {},
  error: null,
};

export const reducerAddItems = (state = initialAddItems, action) => {
  switch (action.type) {
    case "ADD_ITEMS_START":
      return { ...state, loading: true, error: null };
    case "ADD_ITEMS_SUCCESS":
      return { ...state, loading: false, items: action.payload };
    case "ADD_ITEMS_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
