import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCategory } from "../../../context/actions/postaction";
import {
  deleteCategory,
  getCategory,
} from "../../../context/actions/getaction";
import {
  Card,
  Input,
  Button,
  Typography,
  Alert,
  IconButton,
} from "@material-tailwind/react";
import { TrashIcon } from "@heroicons/react/24/outline";

const AddCategory = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  const { category } = useSelector((state) => state.category);

  // استخدام useState لإدارة بيانات النموذج
  const [categoryName, setCategoryName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!categoryName) {
      setErrorMessage("يرجى إدخال اسم الفئة.");
      setSuccessMessage("");
      return;
    }

    const newCategory = {
      name: categoryName,
    };

    try {
      // استدعاء دالة addCategory لإرسال البيانات
      await dispatch(addCategory(newCategory));
      setSuccessMessage("تم إضافة الفئة بنجاح!");
      setErrorMessage("");

      // إعادة تعيين قيمة المدخل بعد الإضافة الناجحة
      setCategoryName("");
      dispatch(getCategory()); // تحديث القائمة بعد إضافة الفئة الجديدة
    } catch (error) {
      setErrorMessage("حدث خطأ أثناء إضافة الفئة. يرجى المحاولة مرة أخرى.");
      setSuccessMessage("");
    }
  };

  const handleDelete = async (id) => {
    try {
      // استدعاء دالة deleteCategory لحذف الفئة
      await dispatch(deleteCategory(id));
      setSuccessMessage("تم حذف الفئة بنجاح!");
      setErrorMessage("");
      dispatch(getCategory()); // تحديث القائمة بعد الحذف
    } catch (error) {
      setErrorMessage("حدث خطأ أثناء حذف الفئة. يرجى المحاولة مرة أخرى.");
      setSuccessMessage("");
    }
  };

  // تصفية الفئات بناءً على قيمة البحث
  const filteredCategories = category.filter((cat) =>
    cat.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="flex justify-center items-center min-h-screen p-4">
      <Card className="w-full max-w-4xl p-6 space-y-6 font-tajawal" dir="rtl">
        {/* نموذج الإدخال */}
        <Typography
          variant="h4"
          color="blue-gray"
          className="text-center mb-4 font-tajawal"
        >
          إضافة فئة جديدة
        </Typography>

        {successMessage && (
          <Alert color="green" className="text-center">
            {successMessage}
          </Alert>
        )}
        {errorMessage && (
          <Alert color="red" className="text-center">
            {errorMessage}
          </Alert>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <Input
            label="اسم الفئة"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            required
            className="font-tajawal text-right"
          />
          <Button type="submit" color="blue" className="w-full font-tajawal">
            حفظ الفئة
          </Button>
        </form>

        {/* حقل البحث */}
        <div className="mt-8">
          <Input
            label="بحث عن فئة"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="font-tajawal text-right mb-4"
          />
        </div>

        {/* جدول عرض الفئات */}
        <div className="mt-4">
          <Typography
            variant="h4"
            color="blue-gray"
            className="text-center mb-4 font-tajawal"
          >
            قائمة الفئات
          </Typography>
          <div className="overflow-x-auto">
            <table className="w-full table-auto border-collapse">
              <thead>
                <tr>
                  <th className="border-b-2 border-blue-gray-100 p-4 text-center font-medium">
                    اسم الفئة
                  </th>
                  <th className="border-b-2 border-blue-gray-100 p-4 text-center font-medium">
                    تاريخ الإنشاء
                  </th>
                  <th className="border-b-2 border-blue-gray-100 p-4 text-center font-medium">
                    إجراءات
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredCategories.map((cat) => (
                  <tr key={cat.objectId} className="hover:bg-blue-gray-50">
                    <td className="border-b border-blue-gray-50 p-4 text-center">
                      {cat.name}
                    </td>
                    <td className="border-b border-blue-gray-50 p-4 text-center">
                      {new Date(cat.createdAt).toLocaleDateString()}
                    </td>
                    <td className="border-b border-blue-gray-50 p-4 text-center">
                      <IconButton
                        color="red"
                        onClick={() => handleDelete(cat.objectId)}
                      >
                        <TrashIcon className="h-5 w-5" />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AddCategory;
